import React, { Component } from "react";
import 'react-tabs/style/react-tabs.css';
import Layout from "../components/layout-partnership";
import ScrollAnimation from 'react-animate-on-scroll';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";

class PartnerTestimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedTestimonials: {},
    };
  }

  componentDidMount() {
    this.initializeSwiper();
  }

  initializeSwiper() {
    // Initialize Swiper after the component mounts
    new Swiper('.testimonial-swiper', {
      slidesPerView: 1, // Show one slide at a time
      spaceBetween: 30,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
    });
  }

  toggleReadMore = (index) => {
    this.setState((prevState) => ({
      expandedTestimonials: {
        ...prevState.expandedTestimonials,
        [index]: !prevState.expandedTestimonials[index],
      },
    }));
  };

  render() {
    const { partnerTestimonials } = this.props;
    const { expandedTestimonials } = this.state;

    function stripHtml(html) {
      return html.replace(/<[^>]*>/g, '');
    }

    return (
      <div>
        <Layout>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ScrollAnimation
                  animateIn="fadeIn"
                  style={{ 'animation-duration': '1s' }}
                >
                  <div className="title">
                    <h2
                      className="wow fadeInUp"
                      data-wow-duration="1s"
                      data-wow-delay=".7s"
                    >
                      {partnerTestimonials.partner_testimonials_title}
                    </h2>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>

          <div className="professors-sec">
            <div className="container">
              <div className="newest-clas">
                <div className="row">
                  <div className="col-12">
                    <ScrollAnimation
                      animateIn="fadeIn"
                      style={{ 'animation-duration': '1s' }}
                    >
                      <div className="swiper-container testimonial-swiper">
                        <div className="swiper-wrapper">
                          {partnerTestimonials.partner_testimonials_detail.map((testimonial, index) => (
                            <div className="swiper-slide" key={index}>
                              <div className="professors-box">
                                <div className="image-holder">
                                  <img
                                    src={testimonial.partner_testimonial_image.source_url}
                                    alt="Support"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="text-box">
                                  <h4>{testimonial.person_name}</h4>
                                  <span>{testimonial.person_designation}</span>
                                  <div>
                                    <span className="testimonial-content">
                                      {expandedTestimonials[index]
                                        ? stripHtml(testimonial.partner_testimonial)
                                        : stripHtml(testimonial.partner_testimonial.substring(0, 300)) + '...'}
                                    </span>
                                    <span
                                      href="#"
                                      onClick={() => this.toggleReadMore(index)}
                                      className="testimonial-read-more-btn"
                                    >
                                      {expandedTestimonials[index]
                                        ? 'Read Less ▲'
                                        : 'Read More ▼'}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {/* Pagination */}
                        <div className="swiper-pagination"></div>
                        {/* Navigation */}
                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>
                      </div>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default PartnerTestimonials;
