import React from "react"
import "../css/sharan-style.css";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";
import "../css/swiper-bundle.min.css";

const Layout = ({ children }) => (

  <div>
    {children}
  </div>

);

export default Layout;