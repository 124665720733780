import React, { Component } from "react"
import Layout from "../components/layout-partnership";
import "../css/sharan-style.css";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";
import "../css/swiper-bundle.min.css";
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper'


Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);


class PartnerUniversities extends Component {

  componentDidMount() {

    var swiper = new Swiper('.partner-swiper', {
			slidesPerView: 1,
			spaceBetween: 50,
			slidesPerGroup: 1,
			loop: false,
			loopFillGroupWithBlank: false,
			watchOverflow: true,
		   
			autoplay: {  
			  delay: 7000, 
			  disableOnInteraction: false 
			},
			pagination: {
			  el: '.swiper-pagination',
			  clickable: true,
			},
		
			breakpoints: { 
				  1200: { slidesPerGroup: 6, slidesPerView: 6 }, 
				  992: { slidesPerGroup: 4, slidesPerView: 4 }, 
				  768: { slidesPerGroup: 2, slidesPerView: 2 }
			  }
			  
		  }); 


   
  }
  render() {
    const { featuredUniversities } = this.props; 
    function  stripHtml(html) {
  
      return html.replace(/<[^>]*>/g, '');
    }
    return (
      <Layout>
      <section className="partner-sec partner-custom-styling">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-3">
              <div className="partner-inner">
                <div className="swiper-container partner-swiper">
                  <div className="swiper-wrapper d-flex align-items-center">
   

                    {featuredUniversities &&
                      featuredUniversities.map((logo, index) => (
                        <div className="swiper-slide logo-position1" key={index}>
                        {
                            Array.isArray(logo.acf.university_detail_page) && logo.acf.university_detail_page.some(page => page.toLowerCase() === "yes") ? (
                                <a href={`/university-detail/${logo.slug}/`} target="_blank" rel="noopener noreferrer">
                                    <img src={logo.acf.university_logo.source_url} alt={logo.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')} />
                                </a>
                            ) : logo.acf.website_url ? (
                                <a href={logo.acf.website_url} target="_blank" rel="noopener noreferrer">
                                    <img src={logo.acf.university_logo.source_url} alt={logo.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')} />
                                </a>
                            ) : (
                                <img src={logo.acf.university_logo.source_url} alt={logo.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')} />
                            )
                        }
                    </div>
                    
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
    )
  }
}
export default PartnerUniversities;
